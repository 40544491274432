import {ApiService} from "./ApiService";

const API = {
  BASE: '/api/admin',
  ALERTS: '/api/admin/alerts',
  PLAYERS: '/api/admin/players',
  REMOVED_FROM_WAIT_LIST: '/api/waitlist/removed'
};

export const AdminService = {
  getAlerts() {
    return ApiService.get(API.ALERTS);
  },
  getDistinctPlayers() {
    return ApiService.get(API.PLAYERS);
  },
  getRemovedFromWaitList() {
    return ApiService.get(API.REMOVED_FROM_WAIT_LIST);
  },
  updatePlayer(playerUpdateData) {
    return ApiService.save(API.PLAYERS, playerUpdateData);
  }
}
