import {useDroppable} from "@dnd-kit/core";

export default function TierListGameArea(props) {
  const {setNodeRef} = useDroppable({
    id: props.tier.sortingUniqueId,
    data: {type: "game"}
  });

  return <td ref={setNodeRef}>
    <div className="d-flex flex-wrap">
      {props.children}
    </div>
  </td>

}
