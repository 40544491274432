export const updatePageTitle = (title) => {
  document.title = 'Planszówki • ' + title;
}

export const dateToString = (date) => {
  const timezoneOffset = date.getTimezoneOffset();
  date.setUTCMinutes(date.getUTCMinutes() - timezoneOffset);
  return date.toISOString().split('T')[0];
};

export const arrayOfNumbers = (from, to) => {
  let result = [];
  for (let i = from; i <= to; i++) {
    result.push(i);
  }
  return result;
}

export const isSmallScreen = () => {
  return isSmallScreenHorizontal() || isSmallScreenVertical();
}

export const isSmallScreenHorizontal = () => {
  return window.innerHeight < 720;
}

export const isSmallScreenVertical = () => {
  return window.innerWidth < 720;
}

export const universalSort = (value1, value2, order) => {
  let result;
  if (value1 == null && value2 != null)
    result = -1;
  else if (value1 != null && value2 == null)
    result = 1;
  else if (value1 == null && value2 == null)
    result = 0;
  else if (typeof value1 === 'string' && typeof value2 === 'string')
    result = value1.localeCompare(value2);
  else
    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
  return result * order;
}

export const minutesToFormattedTime = (minutes) => {
  if (!minutes) {
    return "";
  } else if (minutes >= 60) {
    const h = minutes / 60;
    const m = minutes % 60;
    return Math.floor(h) + "h" + (m ? " " + m + "m" : "");
  } else {
    return minutes + "m";
  }
}

export const formattedTimeToMinutes = (timeString) => {
  const hoursDecimalRegex = /^(?:(\d+\.\d+)h)?$/i;
  const hoursMinutesRegex = /^(?:(\d+)h)?(?:\s*(\d+)m?)?$/i;
  const match1 = timeString.match(hoursDecimalRegex);
  if (match1) {
    const hours = parseFloat(match1[1] || 0);
    return Math.floor(hours * 60);
  }
  const match2 = timeString.match(hoursMinutesRegex);
  if (match2) {
    const hours = parseInt(match2[1] || 0);
    const minutes = parseInt(match2[2] || 0);
    if (hours > 0 && minutes >= 0 && minutes <= 59) {
      return hours * 60 + minutes;
    } else if (hours === 0 && minutes > 0) {
      return minutes;
    } else {
      return 0;
    }
  }
  return 0;
}

export const leftPad = (valueToPad, paddingCharacter, targetLength) => {
  const missingLength = targetLength - valueToPad.toString().length;
  if (missingLength > 0) {
    return paddingCharacter.toString().repeat(missingLength) + valueToPad;
  } else {
    return valueToPad;
  }
}

export const calculateLastAvailableYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  if (currentDate.getMonth() === 11 && currentDate.getDate() >= 24) {
    return currentYear;
  }
  return currentYear - 1;
};

export const cloneObject = object => JSON.parse(JSON.stringify(object));

export const capitalize = text => text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase();

export const randomString10 = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const prefixTierId = id => "tier-header-" + id;

export const unprefixTierId = id => id.replace("tier-header-", "");