import {DndContext, DragOverlay} from "@dnd-kit/core";
import {arrayMove, SortableContext} from "@dnd-kit/sortable";
import TierListGameTile from "./TierListGameTile";
import TierListGameArea from "./TierListGameArea";
import TierListHeaderTile from "./TierListHeaderTile";
import TierListHeaderOverlay from "./TierListHeaderOverlay";
import {useState} from "react";
import {randomString10, universalSort, unprefixTierId} from "../../utilities/CommonUtils";
import {Button} from "primereact/button";

export default function TierListContainer(props) {

  const [activeId, setActiveId] = useState(null);
  const [draggedItemType, setDraggedItemType] = useState(null);

  const getTierId = id => {
    if (props.tierListData.map(data => data.sortingUniqueId).includes(id)) {
      return id;
    }
    return props.tierListData.find(data => data.games.includes(id)).sortingUniqueId;
  }

  const handleDragStart = event => {
    const _draggedItemType = event.active.data.current.type;
    setDraggedItemType(_draggedItemType);
    setActiveId(event.active.id);
  };

  const handleDragEnd = event => {
    if (draggedItemType === "game") handleGameDragEnd(event);
    else if (draggedItemType === "tier") handleTierDragEnd(event);
  };

  const handleGameDragEnd = event => {
    if (!event.over || event.over.data.current.type === "tier") return;
    const gameId = event.active.id;
    const overId = event.over.id;
    const oldTierId = getTierId(gameId);
    const newTierId = getTierId(overId);
    const oldTierGames = props.tierListData.find(data => data.sortingUniqueId === oldTierId).games;
    const newTierGames = props.tierListData.find(data => data.sortingUniqueId === newTierId).games;
    const activeIndex = oldTierGames.indexOf(gameId);
    const overIndex = newTierGames.indexOf(overId);
    if (oldTierId === newTierId) {
      if (activeIndex !== overIndex) {
        const newTierListData = [...props.tierListData];
        newTierListData.find(data => data.sortingUniqueId === newTierId).games = arrayMove(newTierGames, activeIndex, overIndex);
        props.setTierListData(newTierListData);
      }
    } else {
      let newIndex;
      if (props.tierListData.map(data => data.sortingUniqueId).includes(overId)) {
        newIndex = newTierGames.length + 1;
      } else {
        const isAfterLastItem =
          event.over &&
          overIndex === newTierGames.length - 1 &&
          event.active.rect.left > event.over.rect.left + event.over.rect.width;
        const modifier = isAfterLastItem ? 1 : 0;
        newIndex = overIndex >= 0 ? overIndex + modifier : newTierGames.length + 1;
      }
      const newTierListData = [...props.tierListData];
      newTierListData.find(data => data.sortingUniqueId === oldTierId).games = oldTierGames.filter(id => id !== gameId);
      newTierListData.find(data => data.sortingUniqueId === newTierId).games = [...newTierGames.slice(0, newIndex), oldTierGames[activeIndex], ...newTierGames.slice(newIndex, newTierGames.length)];
      props.setTierListData(newTierListData);
    }
    setActiveId(null);
    setDraggedItemType(null);
  };

  const handleTierDragEnd = event => {
    if (!event.over || event.over.data.current.type === "game") return;
    const oldIndex = props.tierListData.find(tier => tier.sortingUniqueId === unprefixTierId(activeId)).tierOrder;
    const newIndex = props.tierListData.find(tier => tier.sortingUniqueId === unprefixTierId(event.over.id)).tierOrder;
    moveTier(oldIndex, newIndex);
    setActiveId(null);
    setDraggedItemType(null);
  };

  const moveTier = (oldIndex, newIndex) => {
    if (newIndex < 0 || newIndex > props.tierListData.length - 2) return;
    const newTierListData = [...props.tierListData];
    const tier = newTierListData.find(tier => tier.tierOrder === oldIndex);
    newTierListData.forEach(tier => {
      if (oldIndex > newIndex) { // move up
        if (tier.tierOrder < oldIndex && tier.tierOrder >= newIndex) tier.tierOrder = tier.tierOrder + 1;
      } else if (oldIndex < newIndex) { // move down
        if (tier.tierOrder > oldIndex && tier.tierOrder <= newIndex) tier.tierOrder = tier.tierOrder - 1;
      }
    });
    tier.tierOrder = newIndex;
    newTierListData.sort((left, right) => universalSort(left.tierOrder, right.tierOrder, 1));
    props.setTierListData(newTierListData);
  };

  const removeTier = (index) => {
    const gamesInRemovedTier = props.tierListData.find(tier => tier.tierOrder === index).games;
    const newTierListData = [...props.tierListData.filter(tier => tier.tierOrder !== index)];
    newTierListData.forEach(tier => {
      if (tier.tierOrder > index) tier.tierOrder = tier.tierOrder - 1;
    });
    const unassigned = newTierListData.find(data => data.id === props.unassignedTierId);
    unassigned.games = [...unassigned.games, ...gamesInRemovedTier];
    props.setTierListData(newTierListData);
  }

  const addTier = () => {
    const newIndex = props.tierListData.length - 1;
    const newLabel = "Tier " + (newIndex + 1);
    const newTier = {
      id: newLabel,
      label: newLabel,
      sortingUniqueId: randomString10(),
      tierOrder: newIndex,
      bgColor: "#ffffff",
      textColor: "#212529",
      games: []
    };
    const newTierListData = [...props.tierListData];
    const unassigned = newTierListData.find(data => data.id === props.unassignedTierId);
    unassigned.tierOrder = unassigned.tierOrder + 1;
    newTierListData.push(newTier);
    newTierListData.sort((left, right) => universalSort(left.tierOrder, right.tierOrder, 1));
    props.setTierListData(newTierListData);
  }

  return <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
    <table className="w-100">
      <tbody>
      {props.editing && <tr>
        <td className="text-center"><Button size="small" className="mb-2" label="Dodaj tier" icon="pi pi-plus-circle" onClick={addTier}/></td>
        <td></td>
      </tr>}
      {props.tierListData.filter(data => props.editing || data.id !== props.unassignedTierId).map(tier => {
        return <tr key={tier.sortingUniqueId || randomString10()} className="tierlist-row">
          <td className="tierlist-header" style={{backgroundColor: tier.bgColor, color: tier.textColor}}>
            {props.editing && tier.id !== props.unassignedTierId ?
              <TierListHeaderTile tier={tier} tierListData={props.tierListData} setTierListData={props.setTierListData} removeTier={removeTier}/>
              : tier.label}
          </td>
          <TierListGameArea tier={tier}>
            <SortableContext items={tier.games} disabled={!props.editing}>
              {tier.games.map(gameId => props.availableGames.find(game => game.id === gameId)).filter(game => game)
                .map(game => <TierListGameTile key={game.id} game={game}/>)}
            </SortableContext>
          </TierListGameArea>
        </tr>
      })}
      </tbody>
    </table>
    <DragOverlay>{activeId ? draggedItemType === "game" ? <TierListGameTile game={props.availableGames.find(game => game.id === activeId)}/> :
      <TierListHeaderOverlay tier={props.tierListData.find(tier => tier.sortingUniqueId === unprefixTierId(activeId))}/> : null}</DragOverlay>
  </DndContext>

}
